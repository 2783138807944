<template>
  <div>
    <v-container>
      <page-title title="Contact">
        <template slot="action">
          <v-btn class="mr-1" color="primary" @click="redirect('Sales.Contact.Add')">Add New</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <card-expansion title="List Data" no-padding>
            <list-data></list-data>
          </card-expansion>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';

export default {
  components: {
    ListData
  },

  data() {
    return {
      dialog: null,

      loading2: false,
      search2: "",
      attrs: {
        boilerplate: true,
        elevation: 0,
      },
      benched: 0,
    };
  },

  computed: {},

  methods: {
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission('contact', 'view', true)
  },
};
</script>